
import { prop, Vue } from "vue-class-component";

class Props {
  isClientParent = prop<boolean>({
    default: true
  });
  showBalance = prop<boolean>({
    default: true
  });
  balance = prop<string>({
    default: "-"
  });
  clientName = prop<string>({
    default: "-"
  });
  clientStatus = prop<string>({
    default: "-"
  });
  walletBalance = prop<string>({
    default: "-"
  });
  createdAt = prop<string>({
    default: "-"
  });
  createdBy = prop<string>({
    default: "-"
  });
  updatedAt = prop<string>({
    default: "-"
  });
  updatedBy = prop<string>({
    default: "-"
  });
  clientFreeze = prop<boolean>({
    default: false
  });
}

export default class InfoDetailClient extends Vue.with(Props) {
  get statusString() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
        return "Menunggu";
      case "rejected":
        return "Ditolak";
      case "approved":
        return "Aktif";
      default:
        return "-";
    }
  }

  get statusBgColor() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
        return "#FEFAE8";
      case "rejected":
        return "#F8E9E9";
      case "approved":
        return "#EBF8FF";
      default:
        return "#AEAEAE";
    }
  }

  get statusTextColor() {
    switch (this.clientStatus.toLowerCase()) {
      case "waiting":
        return "#F09035";
      case "rejected":
        return "#B82025";
      case "approved":
        return "#006644";
      default:
        return "-";
    }
  }
}
